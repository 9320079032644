import React from 'react';
import { Link } from 'gatsby';
import Layout from 'components/layout';

const Thanks = () => (
  <Layout>
    <div style={{ padding: '2rem 4rem', margin: 'auto', maxWidth: '700px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1 style={{ fontSize: '28px', padding: '20px' }}>
          Thanks for reaching out!
        </h1>
        <Link to="/">Return to Home</Link>
      </div>
    </div>
  </Layout>
);

export default Thanks;
